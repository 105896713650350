import {toAbsoluteUrl} from '@/_metronic/helpers'
import clsx from 'clsx'
import {FC, FocusEventHandler, Key, ReactElement, ReactNode} from 'react'
import {ActionMeta, GroupBase, Options, OptionsOrGroups, components} from 'react-select'
import {
  AsyncPaginate,
  ComponentProps,
  LoadOptions,
  UseAsyncPaginateParams,
  withAsyncPaginate,
} from 'react-select-async-paginate'
import Creatable, {CreatableProps} from 'react-select/creatable'
import SVG from 'react-inlinesvg'

export type OptionType = {
  label: string
  value: any
}

export type Additional = {
  page: number
}

const defaultAdditional: Additional = {
  page: 1,
}

type Props = {
  key?: Key | null | undefined
  options?: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined
  onChange?: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void
  placeholder?: ReactNode
  isMulti?: any
  isCreatable?: boolean
  onCreateOption?: (inputValue: string) => void | undefined
  value?: any
  isOptionDisabled?: (option: any, selectValue: Options<any>) => boolean
  isClearable?: boolean
  isDisabled?: boolean
  loadOptions: LoadOptions<OptionType, GroupBase<OptionType>, Additional>
  onBlur?: FocusEventHandler<HTMLInputElement>
  type?: 'normal' | 'filled'
  className?: string
  selectKey?: string
  loadOptionsOnMenuOpen?: boolean
  cacheUniqs?: any[]
  useBody?: boolean
  noOptionsMessage?: (obj: {inputValue: string}) => ReactNode
}

type AsyncPaginateCreatableProps<
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean
> = CreatableProps<OptionType, IsMulti, Group> &
  UseAsyncPaginateParams<OptionType, Group, Additional> &
  ComponentProps<OptionType, Group, IsMulti>

type AsyncPaginateCreatableType = <
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean = false
>(
  props: AsyncPaginateCreatableProps<OptionType, Group, Additional, IsMulti>
) => ReactElement

const CreatableAsyncPaginate = withAsyncPaginate(Creatable) as AsyncPaginateCreatableType

const DropdownOutletOrder: FC<Props> = ({
  onChange,
  placeholder,
  isMulti,
  value,
  isClearable,
  isDisabled,
  loadOptions,
  onCreateOption,
  isCreatable,
  onBlur,
  className,
  type = 'normal',
  selectKey,
  loadOptionsOnMenuOpen = false,
  cacheUniqs,
  useBody = false,
  noOptionsMessage,
}) => {
  if (!document?.body) {
    return null
  }

  return (
    <AsyncPaginate
      key={selectKey}
      debounceTimeout={1000}
      styles={{
        control: (provided) => ({
          ...provided,
          borderRadius: 6,
          background: 'transparent',
        }),
        option: (provided) => ({
          ...provided,
          fontSize: 13,
        }),
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 3,
        }),
        input: (provided) => ({
          ...provided,
          textAlign: 'center',
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
          marginTop: 3,
          display: 'flex',
        }),
      }}
      components={{
        IndicatorSeparator: null,
        // DropdownIndicator: null,
        Control: (props) => (
          <components.Control
            {...props}
            className={clsx(props.className, 'form-select py-2 px-1')}
          />
        ),
        // ClearIndicator: undefined,
        SelectContainer: (props) => (
          <components.SelectContainer {...props} className={clsx(props.className, 'border-0')} />
        ),
        Input: (props) => <components.Input {...props} className='py-0' />,
        ValueContainer: (props) => (
          <components.ValueContainer {...props} className={clsx(props.className, 'fw-bold')} />
        ),
      }}
      defaultValue={value}
      isMulti={isMulti}
      onChange={onChange}
      placeholder={<span className='custom-dropdown-placeholder fw-semibold'>{placeholder}</span>}
      value={value}
      isSearchable
      defaultOptions
      onBlur={onBlur}
      additional={defaultAdditional}
      isClearable={isClearable}
      loadOptions={loadOptions}
      isDisabled={isDisabled}
      noOptionsMessage={noOptionsMessage}
      className={className}
      menuPortalTarget={useBody ? document.body : null}
      loadOptionsOnMenuOpen={loadOptionsOnMenuOpen}
      cacheUniqs={cacheUniqs}
    />
  )
}

export {DropdownOutletOrder}
