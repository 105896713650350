import {BaseResponse} from '@/_metronic/helpers/crud-helper/base-list-response'
import {BaseRequest} from '@/_metronic/helpers/crud-helper/base-request'

export type ValueProvince = {
  value?: string
}

export const initialValueProvince: ValueProvince = {
  value: '',
}

export type FilterProvince = {
  set_provinsi_id?: boolean
  provinsi_id?: string
  set_name?: boolean
  name?: string
}

export const initialFilterProvince: BaseRequest<FilterProvince> = {
  filter: {
    set_provinsi_id: false,
    provinsi_id: '',
    set_name: false,
    name: '',
  },
  limit: 10,
  page: 1,
  order: 'created_at',
  sort: 'ASC',
}

export type Province = {
  name?: string
  provinsi_id?: string
}

export type ProvinceResponse = BaseResponse<Array<Province>>
