import clsx from 'clsx'
import {FC, FocusEventHandler, Key, ReactElement, ReactNode} from 'react'
import {ActionMeta, GroupBase, Options, OptionsOrGroups, components} from 'react-select'
import {
  AsyncPaginate,
  ComponentProps,
  LoadOptions,
  UseAsyncPaginateParams,
  withAsyncPaginate,
} from 'react-select-async-paginate'
import Creatable, {CreatableProps} from 'react-select/creatable'

export type OptionType = {
  label: string
  value: any
}

export type Additional = {
  page: number
}

const defaultAdditional: Additional = {
  page: 1,
}

type Props = {
  key?: Key | null | undefined
  options?: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined
  onChange?: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void
  placeholder?: ReactNode
  isMulti?: any
  isCreatable?: boolean
  onCreateOption?: (inputValue: string) => void | undefined
  value?: any
  isOptionDisabled?: (option: any, selectValue: Options<any>) => boolean
  isClearable?: boolean
  isDisabled?: boolean
  loadOptions: LoadOptions<OptionType, GroupBase<OptionType>, Additional>
  onBlur?: FocusEventHandler<HTMLInputElement>
  type?: 'normal' | 'filled'
  className?: string
  selectKey?: string
  loadOptionsOnMenuOpen?: boolean
  cacheUniqs?: any[]
  useBody?: boolean
  zIndex?: number
  noOptionsMessage?: (obj: {inputValue: string}) => ReactNode
}

type AsyncPaginateCreatableProps<
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean
> = CreatableProps<OptionType, IsMulti, Group> &
  UseAsyncPaginateParams<OptionType, Group, Additional> &
  ComponentProps<OptionType, Group, IsMulti>

type AsyncPaginateCreatableType = <
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean = false
>(
  props: AsyncPaginateCreatableProps<OptionType, Group, Additional, IsMulti>
) => ReactElement

const CreatableAsyncPaginate = withAsyncPaginate(Creatable) as AsyncPaginateCreatableType

const DropdownSelectAsyncPaginate: FC<Props> = ({
  onChange,
  placeholder,
  isMulti,
  value,
  isClearable,
  isDisabled,
  loadOptions,
  onCreateOption,
  isCreatable,
  onBlur,
  className,
  type = 'normal',
  selectKey,
  loadOptionsOnMenuOpen = false,
  cacheUniqs,
  useBody = false,
  zIndex = 3,
  noOptionsMessage,
}) => {
  if (!document?.body) {
    return null
  }

  if (type === 'filled') {
    return (
      <AsyncPaginate
        key={selectKey}
        debounceTimeout={1000}
        styles={{
          control: (provided) => ({
            ...provided,
            border: 'none',
            borderRadius: 6,
          }),
          option: (provided) => ({
            ...provided,
            fontSize: 13,
          }),
          menuPortal: (provided) => ({
            ...provided,
            zIndex, 
          }),
        }}
        components={{
          IndicatorSeparator: null,
          // DropdownIndicator: null,
          Control: (props) => (
            <components.Control
              {...props}
              className={clsx(props.className, 'form-select form-select-solid py-2 px-1 border-0')}
            />
          ),
          // ClearIndicator: undefined,
          SelectContainer: (props) => (
            <components.SelectContainer {...props} className={clsx(props.className, 'border-0')} />
          ),
          Input: (props) => <components.Input {...props} className='py-0' />,
        }}
        defaultValue={value}
        isMulti={isMulti}
        onChange={onChange}
        placeholder={<span className='custom-dropdown-placeholder fw-semibold'>{placeholder}</span>}
        value={value}
        isSearchable
        defaultOptions
        onBlur={onBlur}
        additional={defaultAdditional}
        isClearable={isClearable}
        loadOptions={loadOptions}
        isDisabled={isDisabled}
        noOptionsMessage={noOptionsMessage}
        className={className}
        menuPortalTarget={useBody ? document.body : null}
        loadOptionsOnMenuOpen={loadOptionsOnMenuOpen}
        cacheUniqs={cacheUniqs}
      />
    )
  }

  if (isCreatable) {
    return (
      <CreatableAsyncPaginate
        key={selectKey}
        debounceTimeout={1000}
        styles={{
          option: (provided) => ({
            ...provided,
            fontSize: 12,
          }),
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 3,
          }),
        }}
        components={{
          IndicatorSeparator: null,
          Control: (props) => (
            <components.Control
              {...props}
              className={clsx(props.className, 'custom-dropdown-control')}
            />
          ),
          Input: (props) => (
            <components.Input
              {...props}
              className={clsx(props.className, 'custom-dropdown-input')}
            />
          ),
        }}
        defaultValue={value}
        isMulti={isMulti}
        onChange={onChange}
        placeholder={<span className='custom-dropdown-placeholder fw-semibold'>{placeholder}</span>}
        value={value}
        isSearchable
        defaultOptions
        onBlur={onBlur}
        className={className}
        additional={defaultAdditional}
        isClearable={isClearable}
        loadOptions={loadOptions}
        isDisabled={isDisabled}
        noOptionsMessage={noOptionsMessage}
        menuPortalTarget={useBody ? document.body : null}
        loadOptionsOnMenuOpen={loadOptionsOnMenuOpen}
        cacheUniqs={cacheUniqs}
        onCreateOption={onCreateOption}
      />
    )
  }

  return (
    <AsyncPaginate
      key={selectKey}
      debounceTimeout={1000}
      styles={{
        option: (provided) => ({
          ...provided,
          fontSize: 12,
        }),
        menuPortal: (provided) => ({
          ...provided,
          zIndex,
        }),
      }}
      components={{
        IndicatorSeparator: null,
        Control: (props) => (
          <components.Control
            {...props}
            className={clsx(props.className, 'custom-dropdown-control')}
          />
        ),
        Input: (props) => (
          <components.Input {...props} className={clsx(props.className, 'custom-dropdown-input')} />
        ),
      }}
      defaultValue={value}
      isMulti={isMulti}
      onChange={onChange}
      placeholder={<span className='custom-dropdown-placeholder fw-semibold'>{placeholder}</span>}
      value={value}
      isSearchable
      defaultOptions
      onBlur={onBlur}
      className={className}
      additional={defaultAdditional}
      isClearable={isClearable}
      loadOptions={loadOptions}
      isDisabled={isDisabled}
      noOptionsMessage={noOptionsMessage}
      menuPortalTarget={useBody ? document.body : null}
      loadOptionsOnMenuOpen={loadOptionsOnMenuOpen}
      cacheUniqs={cacheUniqs}
    />
  )
}

export {DropdownSelectAsyncPaginate}
